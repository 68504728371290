var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-row', {
    staticClass: "mt-4 create-new"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('router-link', {
    staticClass: "btn btn-sm btn-default",
    attrs: {
      "to": {
        name: _vm.CustomerSupportManagePath.name
      }
    }
  }, [_c('em', {
    staticClass: "fa fa-backward"
  }), _vm._v(" Manage Customer Service ")])], 1), _c('b-col', [_c('b-form', {
    on: {
      "submit": _vm.onSubmit,
      "reset": _vm.onReset
    }
  }, [_c('div', {
    staticClass: "card mt-4"
  }, [_c('div', {
    staticClass: "bg-success p-3"
  }, [_c('h5', {
    staticClass: "card-title mb-0 font-weight-bold"
  }, [_vm._v(" Add Access New Customer Service ")])]), _c('div', {
    staticClass: "card-body"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "lg": "6"
    }
  }, [_c('b-card', {
    staticClass: "bg-info text-dark"
  }, [_c('p', [_vm._v("(*) Kolom berlabel bintang wajib diisi.")]), _vm._v("Silahkan cek email terlebih dahulu. Jika email belum pernah dibuat maka Anda wajib membuat akun terlebih dahulu.")])], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "lg": "6"
    }
  }, [_c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v("Email *")]), _c('b-input-group', [_c('b-form-input', {
    attrs: {
      "type": "search",
      "placeholder": "Type email"
    },
    on: {
      "change": _vm.setQuerySearchEmail
    },
    model: {
      value: _vm.query_search_email,
      callback: function ($$v) {
        _vm.query_search_email = $$v;
      },
      expression: "query_search_email"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "type": "button",
      "variant": "primary"
    },
    on: {
      "click": _vm.asyncFindEmail
    }
  }, [_vm._v("Check Email")]), _c('b-spinner', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isEmailSearch,
      expression: "isEmailSearch"
    }],
    staticClass: "ml-2",
    attrs: {
      "variant": "dark",
      "label": "Loading .."
    }
  })], 1)], 1)], 1)], 1), _vm.form.username ? _c('b-row', [_c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Username *"
    }
  }, [_c('b-input-group-text', {
    attrs: {
      "id": "username"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.username) + " ")])], 1)], 1)], 1) : _vm._e(), _vm.form.full_name ? _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Full Name"
    }
  }, [_c('b-input-group-text', {
    attrs: {
      "id": "full_name"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.full_name) + " ")])], 1)], 1)], 1) : _vm._e(), _vm.form.last_login ? _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Last Login"
    }
  }, [_c('b-input-group-text', {
    attrs: {
      "id": "last_login"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.form.last_login)) + " ")])], 1)], 1)], 1) : _vm._e(), _vm.form.phone_number ? _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Phone Number"
    }
  }, [_c('b-input-group-text', {
    attrs: {
      "id": "phone_number"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.phone_number) + " ")])], 1)], 1)], 1) : _vm._e(), _vm.form.is_verified ? _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Is Verified"
    }
  }, [_c('b-input-group-text', {
    attrs: {
      "id": "is_verified"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.is_verified) + " ")])], 1)], 1)], 1) : _vm._e(), _vm.form.id ? _c('b-row', [_c('b-col', {
    staticClass: "mt-4"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm._v("Add Access")])], 1)], 1) : _vm._e()], 1)])])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }