<template>
	<b-overlay
		:show="isLoading"
		rounded="lg"
		opacity="0.6"
		spinner-variant="primary"
	>
		<template #overlay>
			<div class="d-flex align-items-center">
				<b-spinner small type="grow" variant="secondary"></b-spinner>
				<b-spinner type="grow" variant="dark"></b-spinner>
				<b-spinner small type="grow" variant="secondary"></b-spinner>
			</div>
		</template>
		<b-row class="mt-4 create-new">
			<b-col cols="12">
				<router-link class="btn btn-sm btn-default" :to="{name: CustomerSupportManagePath.name}">
					<em class="fa fa-backward"></em> Manage Customer Service
				</router-link>
			</b-col>
			<b-col>
        <b-form @submit='onSubmit' @reset='onReset'>
					<div class="card mt-4">
						<div class="bg-success p-3">
							<h5 class="card-title mb-0 font-weight-bold">
								Add Access New Customer Service
							</h5>
						</div>
						<div class="card-body">
							<b-row>
								<b-col lg="6" class="mt-4">
									<b-card class="bg-info text-dark"><p>(*) Kolom berlabel bintang wajib diisi.</p>Silahkan cek email terlebih dahulu. Jika email belum pernah dibuat maka Anda wajib membuat akun terlebih dahulu.</b-card>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="6" class="mt-4">
									<label for="email">Email *</label>
									<b-input-group>
										<b-form-input
											v-model="query_search_email"
											type="search"
											placeholder="Type email"
											@change="setQuerySearchEmail"
										></b-form-input>
										<b-input-group-append>
											<b-button type="button" variant="primary" @click="asyncFindEmail">Check Email</b-button>
											<b-spinner v-show="isEmailSearch" variant="dark" class="ml-2" label="Loading .."></b-spinner>
										</b-input-group-append>
									</b-input-group>
								</b-col>
							</b-row>
							<b-row v-if="form.username">
								<b-col lg="6" class="mt-4">
									<b-form-group label="Username *">
										<b-input-group-text id="username">
											{{ form.username }}
										</b-input-group-text>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row v-if="form.full_name">
								<b-col lg="6">
									<b-form-group label="Full Name">
										<b-input-group-text id="full_name">
											{{ form.full_name }}
										</b-input-group-text>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row v-if="form.last_login">
								<b-col lg="6">
									<b-form-group label="Last Login">
										<b-input-group-text id="last_login">
											{{ formatDate(form.last_login) }}
										</b-input-group-text>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row v-if="form.phone_number">
								<b-col lg="6">
									<b-form-group label="Phone Number">
										<b-input-group-text id="phone_number">
											{{ form.phone_number }}
										</b-input-group-text>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row v-if="form.is_verified">
								<b-col lg="6">
									<b-form-group label="Is Verified">
										<b-input-group-text id="is_verified">
											{{ form.is_verified }}
										</b-input-group-text>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row v-if="form.id">
								<b-col class="mt-4">
									<b-button variant="primary" type="submit">Add Access</b-button>
								</b-col>
							</b-row>
						</div>
					</div>
				</b-form>
			</b-col>
		</b-row>
	</b-overlay>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import { CustomerSupportManagePath } from "../../router/account";

export default {
	name: "newCustomerSupport",
	data() {
		return {
			CustomerSupportManagePath,
			debounce: null,
			query_search_email: '',
			form: {
				id: null,
				username: null,
				full_name: null,
				last_login: null,
				phone_number: null,
				is_verified: null,
			},
			isEmailSearch: false,
		};
	},
	computed: {
		...mapState({
			isError: (state) => state.accessCustomerSupport.isError,
			isLoading: (state) => state.accessCustomerSupport.isLoading,
			errorMessage: (state) => state.accessCustomerSupport.errorMessage,
			successMessage: (state) => state.accessCustomerSupport.successMessage,
			item: (state) => state.accessCustomerSupport.item
		})
	},
	watch: {
		errorMessage: function() {
			if (!this.errorMessage) return;
			this.messageAlert("error", this.errorMessage, 5000);
		},
		successMessage: function() {
			if (!this.successMessage) return;
			this.messageAlert("success", this.successMessage);

			Object.assign(this.$data, this.$options.data.call(this));
			this.$router.push(CustomerSupportManagePath);
		}
	},
	created() {},
	methods: {
		...mapActions("accessCustomerSupport", ["postCustomerSupport"]),
		...mapActions("accessUserEbook", {
			searchUserEbookByEmail: "searchUserEbookByEmail"
		}),
		onSubmit(event) {
			event.preventDefault();
			const data = this.form;
			const payload = {
				id: data.id,
			};
			this.postCustomerSupport(payload);
		},
		onReset() {},
		messageAlert(icon, title, timer = 3000) {
			this.$swal({
				toast: "true",
				position: "top-end",
				icon,
				title,
				showConfirmButton: false,
				timer,
				timerProgressBar: true,
			});
		},
		setQuerySearchEmail(query) {
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				this.query_search_email = query;
			}, 1000);
		},
		clearForm() {
			this.form.id = null;
			this.form.username = null;
			this.form.full_name = null;
			this.form.last_login = null;
			this.form.phone_number = null;
			this.form.is_verified = null;
		},
		asyncFindEmail() {
			this.isEmailSearch = true;
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				const query = this.query_search_email;
				if (!query) {
					this.messageAlert("error", "Email is required");
					this.clearForm();
					this.isEmailSearch = false;
					return;
				}
				this.searchUserEbookByEmail({ q: query })
					.then((response) => {
						this.form.id = response.id;
						this.form.username = response.username;
						this.form.full_name = response.full_name;
						this.form.last_login = response.last_login;
						this.form.phone_number = response.phone_number;
						this.form.is_verified = response.is_verified;
						this.isEmailSearch = false;
					})
					.catch(() => {
						this.messageAlert("error", "Email not found");
						this.clearForm();
						this.isEmailSearch = false;
					});
			}, 1000);
		},
		formatDate(tgl) {
			if (tgl) {
				return moment(tgl).format("DD MMM YYYY hh:mm:ss");
			}
			return "-";
		},
	},
};
</script>
