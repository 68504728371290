import { render, staticRenderFns } from "./newCustomerSupport.vue?vue&type=template&id=b18e00f2&"
import script from "./newCustomerSupport.vue?vue&type=script&lang=js&"
export * from "./newCustomerSupport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports